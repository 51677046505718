@import url("https://fonts.googleapis.com/css2?family=Exo+2&family=Nunito+Sans:wght@400;600;700&display=swap");

:root {
  --clr-primary: #2459ea;
}

* {
  list-style: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.TopBar {
  /* margin-top: -20px; */
  /* margin-bottom: 20px; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* align-items: flex-end; */
  padding: 0px;
  /* gap: 8px; */
  /* box-shadow: 0px 4px 6px #ddd; */

  /* width: 1506px; */
  /* height: 70px; */
  padding-left: 10px;
}

.Title {
  width: 180px;
  height: 50px;

  /* font-family: "Exo 2"; */
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;

  color: #393a40;
}

.Language {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 58px;
  height: 26px;
}

.MuiLinearProgress-colorPrimary {
  color: green !important;
}

.MuiLinearProgress-root {
  /* left: 43%; */
  position: absolute;
  top: 44vh;
}

.t-label-m-caps {
  font-family: 'Nunito Sans';
  font-weight: 700;
  letter-spacing: .1em;
  line-height: 1.4rem;
  font-size: 1rem;
  text-transform: uppercase;
  color: rgb(166 167 180)
}

.t-numbers-m {
  font-family: 'Exo 2';
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.8rem;
  color: var(--clr-primary);
  font-weight: 600;
}