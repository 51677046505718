.hoursColor {
  height: 82vh;
  width: 20px;
  background: linear-gradient(to bottom,
      #ef5da8 0%,
      #a95fcb,
      #825fde,
      #5d5fef,
      #416cf5,
      #0087ff 100%);
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  /* /* left: 97%; */
  left: 68%;
  top: 3%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.wind-risk-color {
  background: linear-gradient(to bottom,
      #3e0303 0%,
      #ed0707,
      #ed7607,
      #ede907,
      #82ed07,
      #07ed7a,
      #137845,
      #0a6d0a 100%);
  height: 81.5vh;
  width: 20px;
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 68%;
  top: 3%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.otherColor {
  /* display: none;
            position: absolute; */
  height: 81.5vh;
  width: 20px;
  background: linear-gradient(to bottom,
      #3e0303 0%,
      #ed0707,
      #ed7607,
      #ede907,
      #82ed07,
      #07ed7a,
      #07b7ed,
      #1607ed 100%);
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 68%;
  top: 3%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.infobox {
  transition: 0.5s;
  top: 50%;
  left: 40%;
  position: absolute;
  background-color: #ffffff;
  z-index: 100;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 10px;
  width: 350px;
  height: 300px;
  padding: 10px;
  border-color: #0465bf;
}

.background-div {
  position: absolute;
  color: black;
  font-weight: normal;
  right: 2%;
  /* bottom: 2%; */
  margin-top: 0.75em;
  height: 88%;
  width: 115px;
  background-color: #d1cece9e;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  color: black;
  font-size: 0.7em;
  z-index: 2;
  font-weight: normal;
  right: 0.7%;
  /* bottom: 2%; */
  border-radius: 10px;
  justify-content: right;
  align-items: right;
  backdrop-filter: saturate(180%) blur(20px);
  overflow: hidden;
}

.zero {
  color: black;
  top: 95%;
  left: 10%;
  position: absolute;
  font-size: 12px;
}

.avg {
  color: black;
  top: 50%;
  left: 10%;
  position: absolute;
  font-size: 12px;
}

.max {
  color: black;
  top: 3%;
  left: 10%;
  position: absolute;
  font-size: 12px;
}

.color-bar-daylight {
  background: linear-gradient(to bottom,
      #0f0 0%,
      /* #ed0707,
    #ed7607, */
      #ff0,
      /* #82ed07,
    #07ed7a, */
      #f00 100%);
  height: 79vh;
  width: 20px;
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 68%;
  top: 6%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.color-bar-pv {
  background: linear-gradient(to bottom,
      #006837 0%,
      #0f8445,
      #219b51,
      #45ad5a,
      #d1e311,
      #fba25b,
      #de3f2e,
      #ca2326,
      #a70226 100%);
  height: 79vh;
  width: 20px;
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 68%;
  top: 6%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.color-bar-energy {
  background: linear-gradient(to bottom,
      #bd0505 0%,
      #ed0707,
      #ed7607,
      #ede907,
      #82ed07,
      #07ed7a,
      #07b7ed 100%);

  /* background: radial-gradient(
        ellipse 60% 15px at bottom,
        #07b7ed 50px,
        transparent 50px
      )
      bottom,
    radial-gradient(
        ellipse 60% 15px at top,
        hsl(0, 91%, 13%) 50px,
        transparent 50px
      )
      top,
    linear-gradient(
      #bd0505,
      #ed0707,
      #ed7607,
      #ede907,
      #82ed07,
      #07ed7a,
      #07b7ed
    ); */
  height: 82vh;
  width: 20px;
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 68%;
  top: 3%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.sensor-placement-color-bar {
  /* display: none;
          position: absolute; */
  height: 82vh;
  width: 20px;
  background: linear-gradient(to bottom,
      #006837 0%,
      #0f8445,
      #219b51,
      #45ad5a,
      #d1e311,
      #fba25b,
      #de3f2e,
      #ca2326,
      #a70226 100%);
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 60%;
  top: 3%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.wind-turbine-color-bar {
  height: 82vh;
  width: 20px;
  background: linear-gradient(to bottom,
      #006837 0%,
      #0f8445,
      #219b51,
      #45ad5a,
      #d1e311,
      #fba25b,
      #de3f2e,
      #ca2326,
      #a70226 100%);
  border-radius: 25px;
  justify-content: right;
  align-items: right;
  left: 60%;
  top: 3%;
  transition: 0.3s;
  z-index: 100;
  position: absolute;
}

.color-bar-common {
  height: 79vh;
  top: 6%;
}