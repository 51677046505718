.NavBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  /* width: 1376px; */
  height: 40px;
  margin-left: 10px;
}
.drop-down {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;

  width: 160px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1c1d20;
  border-radius: 7px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
#rooms-filter.drop-down-item-no-padding {
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.drop-down-wide {
  width: 200px;
}
.ui {
  text-align: center !important;
}
.selected-item {
  background-color: #e9eefd !important;
}
.drop-down-item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
/* .ui.simple.active.dropdown > .menu,
.ui.simple.dropdown:hover > .menu {
  overflow: visible;
  width: auto;
  height: auto;
  top: 140% !important;
  opacity: 1;
  top: 10px;
} */
/* .menu {
  top: 60px !important;
} */
.ui.simple.active.dropdown > .menu.kkk,
.ui.simple.dropdown:hover > .menu.kkk {
  overflow: visible;
  width: auto;
  height: auto;
  top: 40px !important;
  opacity: 1;
  border-radius: 7px;
}
.ui.simple.dropdown > .menu.kkk > .item:active > .menu.kkk,
.ui.simple.dropdown:hover > .menu.kkk > .item:hover > .menu.kkk {
  overflow: visible;
  width: auto;
  height: auto;
  /* top: 60px !important; */
  left: 100% !important;
  opacity: 1;
}

.kkk {
  top: 60px !important;
}
.ui.simple.active.dropdown.drop-down,
.ui.simple.dropdown.drop-down:hover {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.ggg {
  width: 300px;
}
.ui.simple.active.dropdown > .menu.ggg {
  width: 300px;
}
.ui.simple.dropdown:hover > .menu.ggg {
  width: 300px;
}

.ui.simple.dropdown > .menu.ggg > .item:active > .menu.ggg,
.ui.simple.dropdown:hover > .menu.ggg > .item:hover > .menu.ggg {
  width: 300px;
}
.ui.dropdown .menu > .item.ggg {
  width: 300px;
}
.ui.dropdown .menu > .item.change-width {
  width: 300px;
}
.ui.simple.active.dropdown.change-width,
.ui.simple.dropdown.change-width:hover {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.change-width {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;

  width: 148px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1c1d20;
  border-radius: 7px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
