.room_configuration__infobox {
  transition: 0.5s;
  /* top: 50%; */
  /* left: 40%; */
  position: absolute;

  background-color: #ffffff;
  z-index: 100;
  /* margin-top: 10px; */
  /* margin-left: 10px; */
  border-radius: 10px;
  width: 410px;
  top: 8px;
  height: calc(100vh - 8px);
  padding: 10px;
  border: 1px solid #ccc;
}

.room_configuration__general_info__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
}
.room_configuration__general_info__item__label {
  display: inline-block;
  width: 150px;
}
