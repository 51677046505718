.radar-section {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-line-chart {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.radar-chart {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.second-line-chart {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.info-and-p-tag {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.chart-title {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}