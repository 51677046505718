/* Styles for the sensor info box */
.sensor-info-box {
  padding-left: 32px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sensor-info-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
  /* Updated title color */
}

.sensor-info-item {
  margin-bottom: 12px;
  line-height: 1.5;
  color: #555;
  /* Updated text color */
}

.sensor-info-label {
  font-weight: bold;
  color: #777;
  /* Updated label color */
}

.sensor-info-value {
  font-weight: normal;
  /* Added to control value font weight */
}

.unit {
  font-weight: normal;
  font-size: 14px;
  /* Adjusted font size for units */
  color: #999;
  /* Unit text color */
}